module controllers {
    export module master {
        interface IStatusScope extends ng.IScope {
            statusData: interfaces.master.IStatusData;
            statusUpdate(updateData: interfaces.master.IUpdateStatus);
            list: Array<interfaces.applicationcore.IDropdownModel>;
        }

        export class statusControlCtrl {
            static $inject = ["$scope", "statusService", "generalService", "$uibModal", "$q", "bsLoadingOverlayService"];
            public TransactionStatusHistorySearch: { StatusTypeClassificationValueId: number, TransactionId: number };
            public TransactionStatusHistoryList: Array<interfaces.master.ITransactionStatusHistory>

            public SelectedStatus: interfaces.applicationcore.IDropdownModel;

            public Comments: string = "";
            public AuthorisationUsers?: Array<interfaces.master.IStatusAuthorisationUser>;
            public showAuthorisation: boolean;
            public currentStatusAuthorisationLogId: number;

            constructor(private $scope: IStatusScope, private statusService: services.master.statusService,
                public generalService: interfaces.applicationcore.IGeneralService, private $uibModal: ng.ui.bootstrap.IModalService,
                private $q: ng.IQService, private bsLoadingOverlayService) {

                this.showAuthorisation = false;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'status.load'
                },
                    () => {
                        $scope.$watch("statusData", (newValue: interfaces.master.IStatusData, oldValue: interfaces.master.IStatusData) => {
                            if (newValue) {
                                $scope.statusData = newValue;                                
                                this.Comments = '';
                                this.loadAuthoriseUserList();
                            }
                           
                        }, false);
                    });
            }

            public LoadNextStatusList() {
                if (!this.$scope.statusData) {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }

                var nextStatusSearch = {
                    currentStatusId: this.$scope.statusData.CurrentStatusId
                };

                return this.statusService.getNextStatusList().query(nextStatusSearch,
                    (data: Array<interfaces.applicationcore.IDropdownModel>) => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadAuthoriseUserList() {
                this.statusService.getStatusAuthorisationUsers(this.$scope.statusData.EnumStatusType, this.$scope.statusData.Id).query((data: Array<interfaces.master.IStatusAuthorisationUser>) => {
                    this.AuthorisationUsers = data;
                    if (this.AuthorisationUsers && this.AuthorisationUsers.length > 0)
                        this.showAuthorisation = true;

                },
                    (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            public LoadTransactionStatusHistory() {          
                this.TransactionStatusHistorySearch = {
                    StatusTypeClassificationValueId: this.$scope.statusData.EnumStatusType,
                    TransactionId: this.$scope.statusData.Id
                };

                return this.statusService.getTransactionStatusHistory().query(this.TransactionStatusHistorySearch,
                    (data: Array<interfaces.master.ITransactionStatusHistory>) => {
                        this.TransactionStatusHistoryList = data;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            public ChangeStatusClick() {

                if (!this.SelectedStatus || this.SelectedStatus.Id === 0) {
                    return;
                }
                this.$scope.statusUpdate({ newStatusId: this.SelectedStatus.Id, comments: this.Comments });
                this.$scope.list = [];
                this.SelectedStatus = undefined;
            }

            public OpenHistory() {
                this.LoadTransactionStatusHistory().then(
                    () => {

                        var modalInstance = this.$uibModal.open({
                            animation: true,
                            templateUrl: 'templates/modules/applicationmain/master/statusControlViewHistory.html',
                            controller: ['$uibModalInstance', 'TransactionStatusHistoryList', '$scope', ($modalInstance, TransactionStatusHistoryList, $scope) => {
                                $scope.TransactionStatusHistoryList = TransactionStatusHistoryList;
                            }],
                            size: "lg",
                            resolve: {
                                TransactionStatusHistoryList: () => { return this.TransactionStatusHistoryList }
                            }
                        });

                        modalInstance.result.then(() => { }, () => { })

                    },
                    () => {
                        //failed -- Do nothing
                    }
                );

            }

            public changeAuthorisor(statusAuthorisationLogId: number) {
                this.currentStatusAuthorisationLogId = statusAuthorisationLogId;

                this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Change Authorisor</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="vertical-align">
                                        <div class="col-md-12">
                                            <gts-dropdown name="authorisedUser" ng-model="SelectedUser" form="form" load-data="loadAuthorisedUsers()" required="true"></gts-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Close</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, statusService: interfaces.master.IStatusService) => {

                        $scope.ok = () => {
                            if (!$scope.SelectedUser) {
                                this.generalService.displayMessage("A new authorised user is required.", Enum.EnumMessageType.Warning);
                                $uibModalInstance.close();
                            }

                            this.statusService.changePrincipleUser(this.currentStatusAuthorisationLogId,$scope.SelectedUser.Id).save((data: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(data);
                                this.loadAuthoriseUserList();

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                            
                            $uibModalInstance.close();
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss();
                        };

                        $scope.loadAuthorisedUsers = () => {
                            return this.statusService.getAvailableAuthorisingUsers(this.currentStatusAuthorisationLogId).query({
                            }, (resultList) => {
                            }).$promise;
                        };
                    },
                    size: "sm",
                    resolve: {
                    }
                }).result.then((resultString) => {
                    
                });
            }

        };
    }

    angular.module("app").controller("statusControlCtrl", controllers.master.statusControlCtrl);
}